//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//




import DifferenceShieldIcon from '~/assets/img/icons/difference_shield.svg';
import DifferencePercentageIcon from '~/assets/img/icons/difference_speed.svg';
import DifferenceMagicIcon from '~/assets/img/icons/difference_headset.svg';
import DifferenceBriefcaseIcon from '~/assets/img/icons/difference_briefcase.svg';

import LazyHydrate from 'vue-lazy-hydration';

export default {

  components: {
    LazyHydrate
  },
  head(){
    return {
      title: this.$t('page_titles.home') + ' | ' + this.$store.state.entities.webConfiguration.name,
      meta: [
        { hid: 'description', name: 'description', content: 'Antivirus.codes es la primera web especialista en venta de software de seguridad. Con servicio de calidad y atención personalizada, los clientes tienen a su disposición el mejor precio de venta online en la más amplia variedad.' },
        { hid: 'keywords', name: 'keywords', content: ''},
      ],
    };
  },
  data(){
    return {
      //steps: [
      //  { icon: StepSearchIcon, text: 'Elige el mejor antivirus para tus necesidades' },
      //  { icon: StepPayIcon, text: 'Realiza el pago seguro' },
      //  { icon: StepSendIcon, text: 'Recibirás un email con tu número de licencia' },
      //  { icon: StepProtectedIcon, text: 'Ya tienes tu PC protegido de ataques y virus.' }
      //],
      // differences: [
      //   { icon: DifferenceShieldIcon, title: 'Las mejores marcas de antivirus', text: ['Realizamos análisis semestrales de cada una de las herramientas e seguridad del mercado para ofercerte las mejores marcas para proteger tus disposivitos. Seleccionamos entre los mejores antivirus para facilitarte tu vida digital.'] },
      //   { icon: DifferencePercentageIcon, title: 'Los mejores descuentos del mercado', text: ['Buscamos las mejores ofertas de antivirus para ofrecerte los precios más competitivos. Comparamos continuamente nuestras ofertas con las de la competencia, tanto en internet como en tiendas físicas, para poder garantizarte siempre precios bajos.'] },
      //   { icon: DifferenceMagicIcon, title: 'Te ayudamos a elegir lo que necesitas', text: ['A través de nuestro asistente te ayudaremos a elegir el mejor antivirus para tus dispositivos y con un presupuesto ajustado a tus necesidad. Encuentra la mejor herramienta para ti y protege todos tus dispositivos con una única solución.'] },
      //   { icon: DifferenceBriefcaseIcon, title: 'Las mejores ofertas para empresas', text: ['Si dispones de una pequeña empresa, buscas proteger tu infraestructura y los datos de tus clientes, disponemos de las mejores soluciones y precios competitivos del mercado.', 'Ponemos a tu disposición nuestra experiencia para ayudarte.'] }
      // ],
      selectedPlatformRecommendation: null,
      emptyBrands: 6,
    }
  },
  amp: false,

  jsonld(){
    let schemaJsonArray = [];
    let itemListElement = [];
    // Product schema: https://schema.org/Product
    let platformProductsLength = this.userPlatformFeaturedProducts.products.length;
    let platformProducts = this.userPlatformFeaturedProducts.products;
    try{
    let featuredProductBrand = this.featuredSliderProduct ? this.getBrandByID(this.featuredSliderProduct.brand_id) : null;


    for (let i = 0; i < platformProductsLength; i++){

      // Así evitamos tener que buscar el producto si primero no tenemos la marca.
      let productBrand = this.getBrandByID(platformProducts[i].brand_id);

      // Los productos featured, existen independiente de si están habilitados o no. Es necesario comprobar que estén en el store para mostrarlos.
      if(productBrand && this.getProductByID(platformProducts[i].id)){
         itemListElement.push(
          {
            "@type": "ListItem",
            "position": i + 1,
            "item": {
              "@context": "https://schema.org",
              "@type": "Product",
              "id": 'https://antivirus.codes/' + productBrand.slug + `/${platformProducts[i].slug}`,
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": platformProducts[i].average_client_review_score ? platformProducts[i].average_client_review_score : 4.26,
                "reviewCount": platformProducts[i].client_reviews_count ? platformProducts[i].client_reviews_count : 10 // HardCode por si no hay valores de reviews. Dijeron que iban a cargarlo con reviews pero por si acaso.
              },
              "description": platformProducts[i].seo.meta_description,
              "name": platformProducts[i].seo.meta_title,
              "image": platformProducts[i].main_image.data.options_url.web_main,
              "brand": {
                "@type": "Brand",
                "name": productBrand.name,
                "slogan": productBrand.seo.meta_description,
                "image": productBrand.logo_image.data.options_url.web_main,
              },
              "offers": {
                "@type": "AggregateOffer",
                "offerCount": "3",
                "lowPrice": platformProducts[i].prices.data[0].base_price,
                "highPrice": platformProducts[i].prices.data[2].base_price,
                "availability": this.productHasStock(platformProducts[i]) ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
                'priceCurrency': 'EUR'
              }
            }
          }
        );
      }
    }

    /*
    schemaJsonArray.push(
      {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Antivirus Software Store S.L.",
        "address": {
          "@type": "PostalAddress",
          "addressCountry": "Spain",
          "addressRegion": "Tarragona",
          "postalCode": "43204",
          "streetAddress": "Avenida Bellissens 42, Reus"
        },
        "email": "info@antivirus.codes",
        "url": this.webConfiguration.url,
        "image": "/img/logo.webp",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": this.websiteReviews.average_score,
          "reviewCount": this.websiteReviews.pagination.total
        },
      },
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": this.webConfiguration.name,
        "relatedLink": this.webConfiguration.url,
        "breadcrumb": {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": itemListElement
        },
        "mainEntity": this.featuredSliderProduct ? {
          "@type": "Product",
          "name": this.featuredSliderProduct.seo.meta_title,
          "description": this.featuredSliderProduct.seo.meta_description,
          "image": this.featuredSliderProduct.main_image.data.options_url.web_main,
          "brand": {
            "@type": "Brand",
            "name": featuredProductBrand.name,
            "slogan": featuredProductBrand.seo.meta_description,
            "image": featuredProductBrand.logo_image.data.options_url.web_main,
          },
          "offers": {
            "@type": "AggregateOffer",
            "offerCount": "3",
            "lowPrice": this.featuredSliderProduct.prices.data[0].base_price,
            "highPrice": this.featuredSliderProduct.prices.data[2].base_price,
            "availability": "https://schema.org/InStock", // Featured Product siempre está en stock, está y no está, usted me entiende
            'priceCurrency': 'EUR'
          }
        } : {}
      },
    )
    */
    } catch (error){
      console.log(error)
    }
    return schemaJsonArray;
  },
  computed: {

    differences(){
        return [
        {
          icon: DifferenceShieldIcon,
          title: this.$t('labels.pages.home.best_brands.differences.first.title'),// 'Las mejores marcas de antivirus',
          text: this.$t('labels.pages.home.best_brands.differences.first.text')//['Realizamos análisis semestrales de cada una de las herramientas e seguridad del mercado para ofercerte las mejores marcas para proteger tus dispositivos. Seleccionamos entre los mejores antivirus para facilitarte tu vida digital.']
        },
        {
          icon: DifferencePercentageIcon,
          title: this.$t('labels.pages.home.best_brands.differences.second.title'),
          text: this.$t('labels.pages.home.best_brands.differences.second.text')//['Buscamos las mejores ofertas de antivirus para ofrecerte los precios más competitivos. Comparamos continuamente nuestras ofertas con las de la competencia, tanto en internet como en tiendas físicas, para poder garantizarte siempre precios bajos.']
        },
        {
          icon: DifferenceMagicIcon,
          title: this.$t('labels.pages.home.best_brands.differences.third.title'),
          text: this.$t('labels.pages.home.best_brands.differences.third.text')//['A través de nuestro asistente te ayudaremos a elegir el mejor antivirus para tus dispositivos y con un presupuesto ajustado a tus necesidades. Encuentra la mejor herramienta para ti y protege todos tus dispositivos con una única solución.']
        }/*,
        {
          icon: DifferenceBriefcaseIcon,
          title: this.$t('labels.pages.home.best_brands.differences.fourth.title'),
          text: this.$t('labels.pages.home.best_brands.differences.fourth.text')//['Si dispones de una pequeña empresa, buscas proteger tu infraestructura y los datos de tus clientes, disponemos de las mejores soluciones y precios competitivos del mercado.', 'Ponemos a tu disposición nuestra experiencia para ayudarte.']
        }*/
      ]
    },
    productsToCompare(){

      return this.products.filter(prod => prod.security_level_id === 1)
                                                .sort((a,b) => a.average_rating_score < b.average_rating_score ? 1 : -1)
                                                .slice(0, 5);
    },
    brands(){
      return this.$store.state.entities.brands.filter((brand)=> brand.id !== 14);
    },
    characteristics(){
      return this.$store.state.entities.characteristics;
    },
    platforms(){
      return this.$store.state.entities.platforms;
    },
    webConfiguration(){
      return this.$store.state.entities.webConfiguration;
    },
    homeCharity(){
      return this.$store.state.entities.webConfiguration.home.charity?.data;
    },
    products(){
      return this.$store.state.entities.products;
    },
    homeSliderSlides(){
      return this.webConfiguration.sliders.home.data
        .filter(slide => (slide.type == 'product' && (
          this.$store.state.entities.products.find(product => slide.product_id == product.id && this.getBrandByID(product.brand_id))))
          || slide.type == 'custom');
    },
    websiteReviews(){
      return this.$store.state.entities.websiteReviews;
    },
    platformRecommendations(){
      let platformRecommendations = this.$store.state.entities.webConfiguration.recommended_product_by_platforms;
      let platformsLength = this.platforms.length;
      let arr = [];

      //Buscamos la plataforma segun id
      let findPlatform = (platformId) => {
        for (let i = 0; i < platformsLength; i++){
          if ( this.platforms[i].id === platformId){
            return this.platforms[i];
          }
        }
      }
      // Pusheamos el item al array
      platformRecommendations.forEach(pr => {
          arr.push({
            platform: findPlatform(pr.platform_id),
            product: this.getProductByID(pr.product_id)
          })
      })
      // Devolvemos el array
      return arr;
      //return this.$store.state.entities.webConfiguration.recommended_product_by_platforms.map(rec => ({
      //  platform: this.$store.state.entities.platforms.find(pl => pl.id === rec.platform_id),
      //  product: this.$store.state.entities.products.find(pr => pr.id === rec.product_id),
      //}));
    },
    userPlatformFeaturedProducts() {
      const userPlatform = this.$store.getters.userClosestPlatform;

      const products = this.webConfiguration.featured_products_by_platforms
        .filter((p) => p.platform_id === userPlatform.id)
        .map((p) => this.getProductByID(p.product_id));

      return {
        platform: userPlatform,
        products: products
      }
    },
    featuredSliderProduct(){
      let slideProduct = this.homeSliderSlides.find(slide => slide.type === 'product');

      let productsLength = this.products.length;
      let prod = null;
      if (slideProduct){
        for(let i = 0; i < productsLength; i++){
          if (this.products[i].id === slideProduct.product_id && this.getBrandByID(this.products[i].brand_id)){
              prod = this.products[i];
          }
        }
      }
      return prod//return this.$store.state.entities.products.find(product => product.id == slideProduct?.product_id && this.getBrandByID(product.brand_id));
    },
  },
  watch: {
    platformRecommendations: {
      immediate: true,
      handler(platformRecommendations){
        this.selectedPlatformRecommendation = platformRecommendations.find(pr => {
          return this.$store.getters.userClosestPlatform.id === pr.platform.id;
        }) || null;
      },
    }

  },
  methods: {
    purchase(product, licences){
      this.$store.dispatch('cart/addToCart', {product, licences});
      this.$tracking.addToCart(product, licences);
      this.$tracking.v4.addToCart(product, licences, 'Home');
      this.$router.push(this.localePath({name: 'cart'}));
    },
    trackProductClick(product){
      this.$tracking.productClick(product, 'Home');
      this.$tracking.v4.selectItem(product, 'Home');
    },
    productHasStock(product){
      let check = false;
      for(let i = 0; i < product.prices.data.length; i++){
        if (product.prices.data[i].stock > 0){
          check = true;
        }
      }
      return check;
    },
    getBrandByID(id){
      let brands = this.$store.state.entities.brands;
      let brandsLength = this.$store.state.entities.brands.length;
      let i = 0;

      while (i < brandsLength){
        if(brands[i].id === id){
          return brands[i];
        }
        i += 1;

      }
      //for(let i = 0; i < brandsLength; i++){
      //  if(brands[i].id === id){
      //    return brands[i];
      //  }
      //}
      //return this.$store.state.entities.brands.find(brand => brand.id === id);
    },
    getProductByID(id){
      return this.products.find((p) => p.id === id);
    },

  },
  mounted(){
    const QUERY_SECTION = this.$route.query.section;

    setTimeout(() => {
      if(QUERY_SECTION && this.$refs[QUERY_SECTION] && process.client){
        window.scroll({
          top: this.$refs[QUERY_SECTION].$el.offsetTop,
          behavior: 'smooth'
        });
      }
    }, 500);

    this.$root.$on('home-brands', () => {
      console.log('home-brands event');
      if(process.client){
        window.scroll({
        top: this.$refs['home-brands'].$el.offsetTop,
        behavior: 'smooth'
      });
      }
    })


    setTimeout(() => {
    let recommendations = [];
    for (let i = 0; i < this.platformRecommendations.length; i++){
      if(this.platformRecommendations[i].product !== undefined && this.getBrandByID(this.platformRecommendations[i].product.brand_id) !== undefined){
        recommendations.push(this.platformRecommendations[i].product);
      }
    }

    //this.platformRecommendations.filter(recommendation => recommendation.product !== undefined && this.getBrandByID(recommendation.product.brand_id) !== undefined).map(recommendation => {
    //  recommendations.push(recommendation.product);
    //});
    let featuredProducts = this.userPlatformFeaturedProducts.products.filter(product => product !== undefined && this.getBrandByID(product.brand_id) !== undefined);
    const printedProducts = [
      ...recommendations,
      ...featuredProducts,
    ];


        this.$tracking.impressions(printedProducts, 'Home');
        this.$tracking.v4.itemList(printedProducts, 'Home');
    }, 5000);
  },

}
