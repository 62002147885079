//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'TransitionExpand',
    props: {
        group: {
            required: false,
            type: Boolean
        }
    },
    methods: {
        afterEnter(element){
            element.style.height = 'auto';
        },
        enter(element){
            const width = getComputedStyle(element).width;
            element.style.width = width;
            
            element.style.visibility = 'hidden';
            element.style.height = 'auto';
            const height = getComputedStyle(element).height;
            element.style.width = null;
            element.style.positin = null;
            element.style.visibility = null;
            element.style.height = 0;

            getComputedStyle(element).height;

            requestAnimationFrame(() => {
                element.style.height = height;
            })
        },
        leave(element){
            const height = getComputedStyle(element).height;
            element.style.height = height;
            getComputedStyle(element).height;
            requestAnimationFrame(() => {
                element.style.height = 0;
            })
        }
    }
}
