//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'HomeBrandBlocks',
    props: ['brands'],
    data(){
        return {
            emptyBrands: 0,
            brandsPerLine: 0
        }
    },
    methods:{
        emptyBrandsCalculator(brandsPerLine){
            let brands = this.brands.length;// La cantidad de marcas por línea
            this.emptyBrands = brandsPerLine - (brands % brandsPerLine);
        },
        blocksPerLineSetter(){
            if(document.body.clientWidth > 900){
                this.emptyBrandsCalculator(6);
            } else if (document.body.clientWidth < 900 && document.body.clientWidth > 600){
                this.emptyBrandsCalculator(4);
            } else {
                this.emptyBrandsCalculator(2);
            }
            
        }
    },
    mounted(){
        this.blocksPerLineSetter();
        window.addEventListener('resize', () => {
            this.blocksPerLineSetter();   
        }, {passive: true});
    }

}
