//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        faqs: {
            required: true,
            type: Array
        }
    },
    data(){
        return {
            selectedFaq: ''
        }
    },
    computed: {
        sortedFaqs(){
            return this.faqs.sort((a, b) => a.order - b.order   );
        }
    },
    methods: {
        selectFaq(faqIndex){
            this.selectedFaq = faqIndex;
        },
        closeFaq(){
            this.selectedFaq = '';
        }
    }
}
