import { render, staticRenderFns } from "./index.vue?vue&type=template&id=17a0761a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=17a0761a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17a0761a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeBrandBlocks: require('/var/www/sites/antivirus.codes/components/HomeBrandBlocks.vue').default,ProductBox: require('/var/www/sites/antivirus.codes/components/ProductBox.vue').default,Faqs: require('/var/www/sites/antivirus.codes/components/Faqs.vue').default})
